//custom bootstrap variables
$spacer: 1rem;
$spacers: (
  1: (
    $spacer * 0.125 * 0.5,
  ),
  2: (
    $spacer * 0.125,
  ),
  3: (
    $spacer * 0.125 * 1.5,
  ),
  4: (
    $spacer * 0.25,
  ),
  6: (
    $spacer * 0.125 * 3,
  ),
  8: (
    $spacer * 0.5,
  ),
  10: (
    $spacer * 0.625,
  ),
  12: $spacer * 0.725,
  14: $spacer * 0.875,
  16: $spacer,
  18: $spacer * 1.125,
  20: $spacer * 1.25,
  23: $spacer * 1.5 - $spacer * 0.125 * 0.5,
  24: (
    $spacer * 1.5,
  ),
  30: (
    $spacer * 0.625 * 3,
  ),
  32: (
    $spacer * 2,
  ),
  40: (
    $spacer * 2.5,
  ),
  48: (
    $spacer * 3,
  ),
  64: (
    $spacer * 4,
  ),
);

$border-radius-lg: 0.5rem;
$caret-spacing: 3.255rem;

$blue: #4c84ff;
$red: #ef7878;
$teal: #57d6b1;
$purple: #c685b2;
$yellow: #ffe767;
$bronse: #a88f8e;

$gray-100: #f7f8fc;
$gray-200: #e3e3e3;
$gray-300: #dadde7;
$gray-400: #b0b2ba;
$gray-700: #464646;
$gray-800: #3e4049;
$gray-900: #2b3238;
$secondary: $gray-300;

$font-weight-bold: 600;

$font-family-base: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.css";

body {
  background-color: $gray-100 !important;
}

//sizes

.size-notifications {
  width: 409px;
  height: 492px;
}

.size-menu {
  width: 242px;
  height: 100vh;
  min-height: 100vh;
}

.size-notifications-icon {
  width: 15px;
  height: 15px;
}

.width-content {
  width: 1013px;
  @media (max-width: 1200px) {
    width: 100%;
  }
}

.width-search {
  width: 381px;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.width-filter {
  width: 182px;
}

.width-popover {
  width: 621px;
  min-width: 621px;
}

.width-16 {
  width: 1rem;
}

.width-title {
  width: 160px;
  min-width: 160px;
  @media (max-width: 360px) {
    width: 110px;
    min-width: 110px;
  }
}

.width-logo {
  width: 26px;
  min-width: 26px;
}

.max-width-240 {
  max-width: 240px;
}

.height-header {
  height: 46px;
  @media (max-width: 768px) {
    height: 86px;
  }
}

.right-16 {
  right: $spacer;
}

.size-student-card {
  width: 288px;
  height: 530px;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.size-major-card {
  width: 300px;
  height: 294px;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.height-progress {
  height: 5px;
}

.width-card {
  width: 450px;
  @media (max-width: 992px) {
    width: 100%;
  }
}

.width-card-big {
  width: 529px;
}

.width-modal,
.width-create-Major {
  width: 819px;
  @media (max-width: 992px) {
    width: 100%;
  }
}

.w-30 {
  width: 30%;
}

.w-70 {
  width: 70%;
}

//positions

.left-16 {
  left: 1rem;
}

.bottom-16 {
  bottom: 1rem;
}

.top-0 {
  top: 0;
}

.top-5 {
  top: 5px;
}

.bottom-0 {
  bottom: 0;
}

.right-0 {
  right: 0;
}

.z-index-modal {
  z-index: $zindex-modal;
}

//text

.text-8 {
  font-size: $font-size-base * 0.5 !important;
}

.text-14 {
  font-size: $font-size-base * 0.875 !important;
}

.text-16 {
  font-size: $font-size-base !important; //16px
}

.text-18 {
  font-size: $font-size-base * 1.125 !important; //18px
}

h2 {
  font-size: $font-size-base * 1.5 !important;
}

h4 {
  font-size: $font-size-base * 1.125 !important;
}

h5 {
  font-size: $font-size-base !important;
}

h6 {
  font-size: $font-size-base * 0.875 !important;
}

.text-white-70 {
  color: rgba($white, 0.7) !important;
}

.text-gray-500 {
  color: $gray-500;
}

.text-gray-700 {
  color: $gray-700;
}

.text-muted {
  color: $gray-400 !important;
}
.hover-underline:hover span {
  text-decoration: underline;
}

//borders
.border-05 {
  border: $border-width * 0.5 !important;
  border-style: solid !important;
}

.border-left-3 {
  border-left: $border-width * 3 !important;
  border-left-style: solid !important;
}

.border-gray-100 {
  border-color: $gray-100 !important;
}

.border-gray-200 {
  border-color: $gray-200 !important;
}

.border-gray-300 {
  border-color: $gray-300 !important;
}

.border-gray-400 {
  border-color: $gray-400 !important;
}

.border-gray-600 {
  border-color: $gray-600 !important;
}

//colors
.bg-gray-300 {
  background-color: $gray-300 !important;
}

.bg-gray-700 {
  background-color: $gray-700 !important;
}

.bg-gray-800 {
  background-color: $gray-800 !important;
}

.bg-transparent {
  background-color: transparent;
}

.bg-purple {
  background-color: $purple;
}

.bg-red {
  background-color: $red;
}

.bg-teal {
  background-color: $teal;
}

.bg-yellow {
  background-color: $yellow;
}

.bg-bronse {
  background-color: $bronse;
}

.bg-blue {
  background-color: $blue;
}

.hover-bg-gray-700:hover {
  background-color: $gray-700 !important;
}

.hover-blue:hover {
  background-color: $blue !important;
  color: $gray-100 !important;
  border-color: $blue !important;
  span .text-muted {
    color: $gray-100 !important;
  }
  span .text-dark {
    color: $white !important;
  }

  svg {
    fill: $gray-100 !important;
  }
}

.hover-elevate-200:hover {
  background-color: $gray-200;
  border-radius: 100%;
  box-shadow: 0px 0px 0px 3px $gray-200;
}

.hover-elevate-400:hover {
  background-color: $gray-400;
  border-radius: 100%;
  box-shadow: 0px 0px 0px 3px $gray-400;
}

// .hover-fill-green:hover svg {
//   fill: $green;
// }

// .hover-fill-yellow:hover svg {
//   fill: $yellow;
// }

// .hover-fill-red:hover svg {
//   fill: $red;
// }

//flex
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

//other
.cursor-pointer {
  cursor: pointer;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.focus:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-45 {
  transform: rotate(45deg);
}

.no-after::after {
  display: none !important;
}

.no-before::before {
  display: none !important;
}

.no-outline {
  outline: none;
}

///mobile
@media (max-width: 1200px) {
  .hide-mobile {
    display: none;
  }

  .flex-column-mobile {
    flex-direction: column;
  }

  .show-mobile {
    display: block !important;
  }

  .text-gray-600-mobile {
    color: $gray-600 !important;
  }

  .border-left-mobile-0 {
    border-left: 0 !important;
  }
}
@media (max-width: 768px) {
  .mobile-flex-column-reverse {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
}
